import React,{Fragment} from 'react'
import {Row,Col} from  'antd'
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import {
    DollarOutlined
}from '@ant-design/icons'
import './index.css'

class OverviewCountup extends React.Component{
    constructor(){
        super()
        this.state={
            active:true,
            active2:true
        }
    }
    handleChange=()=>{
        this.setState({
            active:false
        })
    }
    handleChange2=()=>{
        this.setState({
            active2:false
        })
    }
    render(){
        return(
            <Fragment>
                <Row
                className="overview-countup-row"
                >
                    <div
                    // span={6}
                    className="overview-countup-col"
                    >
                        <h2 className="countNumber">
                            <CountUp
                            // decimals={4}
                            duration={3}
                            start={0} 
                            end={75000}
                            delay={0}
                            >
                                    {({ countUpRef}) => (
                                       
                                        <p>
                                            <span ref={countUpRef}/>
                                        </p>
                                    
                                    )}
                            </CountUp>
                            
                        </h2>
                        <h4 className="countTitle">Total Workforce</h4>
                    </div>
                    <div
                    // span={6}
                    className="overview-countup-col"
                    >
                        <h2 className="countNumber">
                            <CountUp 
                            duration={3} 
                            start={0} 
                            end={450} 
                            delay={0}>
                                    {({ countUpRef}) => (
                                       
                                        <p>
                                            <span ref={countUpRef}/>
                                        </p>
                                        
                                    )}
                            </CountUp>
                            
                        </h2>
                        <h4 className="countTitle">Production Line</h4>
                    </div>
                    <div
                    // span={6}
                    className="overview-countup-col"
                    >
                        <h2 className="countNumber">
                            <CountUp 
                            start={0} 
                            duration={3}  
                            end={11}
                            delay={0}
                            >
                                    {({ countUpRef}) => (
                                        
                                        <p>
                                            <span ref={countUpRef}/>
                                        </p>
                                        
                                    )}
                            </CountUp>
                            
                        </h2>
                        <h4 className="countTitle">Mega Factory</h4>
                    </div>
                    <div
            
                    className="overview-countup-col"
                    >
                        <h2 className="countNumber">
                            <CountUp 
                            start={0} 
                            duration={3} 
                            end={8}
                            delay={0}
                            >
                                    {({ countUpRef}) => (
                                
                                            <p>
                                                {/* <DollarOutlined /> */}
                                                <span ref={countUpRef}/>{' M'}
                                            </p>
                                     
                                    )}
                            </CountUp>
                            
                        </h2>
                        <h4 className="countTitle">Production Capacity (Month)</h4>
                    </div>
                </Row>
            </Fragment>
        )
    }
}
export default OverviewCountup