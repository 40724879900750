import React,{Fragment, useEffect, useState} from 'react'
import {Row,Col,Menu} from 'antd'
import {Link} from 'react-router-dom'
import Logo from '../../assets/images/hglogo.png'
import HLogo from '../../assets/images/HameemLogo.png'
import { useLocation } from 'react-router-dom'
const SubMenu = Menu.SubMenu;

const TopNavBar=(props)=>{
    const location=useLocation();
    const [hLogo,setHLogo]=useState("");
    const {pathname}=location
    //console.log("Path Name : ",pathname);
    useEffect(()=>{
        setHLogo("https://firebasestorage.googleapis.com/v0/b/uploadingfile-6133e.appspot.com/o/Ha-meem%2FLogo%2FHameemLogo.png?alt=media&token=11d1cf26-e2fd-4deb-8b25-7da5bd93d3a0")
    },[]);
    return(
        <Fragment>
            <title>Ha-Meem - {props.title}</title>
            <Row
            style={{
                height:"90px",
                display:"flex",
                alignItems:"center",
                textAlign:"center",
            }}
            >
                <Col 
                span={4}
                >
                    <img 
                    style={{
                        height:"55px",
                        width:"90px"
                    }}
                    src={hLogo}
                    />
                </Col>
                <Col
                span={20}
                style={{
                    padding:"0px"
                }}
                >
                    <Menu
                    // theme='light'
                    style={{
                        margin:"0px",
                        height:"88px",
                        float:"right",
                        paddingRight:"3vw"
                    }}
                    mode="horizontal"
                    // subMenuCloseDelay={.4}
                    // subMenuOpenDelay={.3}
                    >
                        {/* <SubMenu
                        title={
                        <strong>
                            HOME
                        </strong>
                        }
                        >
                            <Menu.Item>
                                <Link to='/'>
                                    Home-01
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/home1'>
                                    Home-02
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/home1'>
                                    Home-03
                                </Link>
                            </Menu.Item>
                        </SubMenu> */}
                        <Menu.Item
                        className={pathname==="/"?"active-parent":"menu-no-child"}
                        key="0"
                        >
                            <Link to="/">
                                <strong>
                                    Home
                                </strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                        className={pathname==="/about"?"active-parent":"menu-no-child"}
                        key="1"
                        >
                            <Link to="/about">
                                <strong>
                                    About
                                </strong>
                            </Link>
                        </Menu.Item>
                        {/* <SubMenu
                        title={
                        <strong>
                            PAGES
                        </strong>
                        }
                        >
                            <Menu.Item>
                                <Link to='/'>
                                    About Us
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    Services
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    Our Team
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    Team Details
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    Our Team
                                </Link>
                            </Menu.Item>
                        </SubMenu> */}
                        <SubMenu
                        title={
                        <strong>
                            industries
                        </strong>
                        }
                        className={pathname==="/" || pathname==="/about" || pathname==="/sustainability" || pathname==="/contact" || pathname==="/career"?"industry-sub-menu":"industry-sub-menu active-submenu"}
                        key="101"
                        >
                            <Menu.Item
                            className={pathname==="/apparel"?"child-menu-item active-child":"child-menu-item"}
                            key="3"
                            >
                                <Link to='/apparel'>
                                    Apparel
                                </Link>
                            </Menu.Item>
                            <Menu.Item
                            className={pathname==="/laundry"?"child-menu-item active-child":"child-menu-item"}
                            
                            key="4"
                            >
                                <Link to='/laundry'>
                                    Laundry
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/fabricmill"?"child-menu-item active-child":"child-menu-item"}
                            key="5"
                            >
                                <Link to='/fabricmill'>
                                    Textile
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/trimaccessories"?"child-menu-item active-child":"child-menu-item"}
                            key="6"
                            >
                                <Link to='/trimaccessories'>
                                    Trims & Accessories Unit
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/embroidery"?"child-menu-item active-child":"child-menu-item"}
                            key="7"
                            >
                                <Link to='/embroidery'>
                                    Embroidery Unit
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/printingunit"?"child-menu-item active-child":"child-menu-item"}
                            key="8"
                            >
                                <Link to='/printingunit'>
                                    Printing Unit
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/transportation"?"child-menu-item active-child":"child-menu-item"}
                            key="9"
                            >
                                <Link to='/transportation'>
                                    Transportation Unit
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className="child-menu-item"
                            key="10"
                            >
                                <Link to='/'>
                                    C & F
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/printmedia"?"child-menu-item active-child":"child-menu-item"}
                            key="100"
                            >
                                <Link to='/printmedia'>
                                    Print Media
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/electronicsmedia"?"child-menu-item active-child":"child-menu-item"}
                            key="11"
                            >
                                <Link to='/electronicsmedia'>
                                    Electronics Media
                                </Link>
                            </Menu.Item>
                            <Menu.Item  
                            className={pathname==="/teastate"?"child-menu-item active-child":"child-menu-item"}
                            key="12"
                            >
                                <Link to='/teastate'>
                                    Tea State
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        {/* <SubMenu
                        title={
                        <strong>
                            PROJECTS
                        </strong>
                        }
                        >
                            <Menu.Item>
                                <Link to='/'>
                                    2 - Columns - Modern
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    3 - Columns - Standard
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    4 - Columns - Full Standard
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    4 - Columns - Full Grid
                                </Link>
                            </Menu.Item>
                        </SubMenu> */}
                        {/* <SubMenu
                        title={
                        <strong>
                            BLOG
                        </strong>
                        }
                        >
                            <Menu.Item>
                                <Link to='/'>
                                    Blog Classic
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    Blog Details
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/'>
                                    Blog - Right - Sidebar
                                </Link>
                            </Menu.Item>
                        </SubMenu> */}
                        <Menu.Item
                        className={pathname==="/sustainability"?"active-parent":"menu-no-child"}
                        key="13"
                        >
                            <Link to="/sustainability">
                                <strong>
                                sustainability
                                </strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                        className={pathname==="/career"?"active-parent":"menu-no-child"}
                        key="0"
                        >
                            <Link to="/career">
                                <strong>
                                    Career
                                </strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                        className={pathname==="/contact"?"active-parent":"menu-no-child"}
                        key="14"
                        >
                            <Link to='/contact'>
                                <strong>CONTACT</strong>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Col>
                
            </Row>
        </Fragment>
    )
}
export default TopNavBar;