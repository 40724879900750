import React, { Fragment,Suspense } from 'react';
import { Row,Col,Button } from 'antd';
import './index.css';
import GarmentsSlideShow from './Garments';
import WashingSlideShow from './Washing';
import SpiningSlideShow from './Spining';
import TextileSlideShow from './Textiles';


class ContentSlideShow extends React.Component{
    state={
        activeSlide:2
    }
    handleActiveSlide=(value)=>{
        this.setState({
            activeSlide:value
        })
    }
    render(){
        let {activeSlide}=this.state;
        let ActiveComponent={};

        if(activeSlide=="2"){
            ActiveComponent=<Suspense enabled={true} fallback={<h2>Loading...</h2>}>
                <GarmentsSlideShow/>
            </Suspense>
            
        }else if(activeSlide=="3"){
            ActiveComponent=<WashingSlideShow/>
        }else if(activeSlide=="4"){
            ActiveComponent=<SpiningSlideShow/>
        }else if(activeSlide=="5"){
            ActiveComponent=<TextileSlideShow/>
        }
        return(
            <Fragment>
                <Row className="slideshow-filter-row">
                    <Col span={24}>
                        <Row>
                            <Col span={24} className="button-col">
                                <Button
                                onClick={this.handleActiveSlide.bind(this,2)}
                                className={activeSlide=="2"?"slideshow-filter-btn active-btn":"slideshow-filter-btn"}
                                >
                                    Garments
                                </Button>
                                <Button
                                onClick={this.handleActiveSlide.bind(this,3)}
                                className={activeSlide=="3"?"slideshow-filter-btn active-btn":"slideshow-filter-btn"}
                                >
                                    Washing
                                </Button>
                                <Button
                                onClick={this.handleActiveSlide.bind(this,4)}
                                className={activeSlide=="4"?"slideshow-filter-btn active-btn":"slideshow-filter-btn"}
                                >
                                    Spining
                                </Button>
                                <Button
                                onClick={this.handleActiveSlide.bind(this,5)}
                                className={activeSlide=="5"?"slideshow-filter-btn active-btn":"slideshow-filter-btn"}
                                >
                                    Textiles
                                </Button>
                            </Col>
                        </Row>
                        <Row className="slideshow-content">
                            <Col span={24}>
                                {ActiveComponent}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
export default ContentSlideShow;