import React, { Fragment } from 'react'
import {Row,Col,Card} from 'antd'
import './index.css'

import IndustryBlack from '../../assets/images/industry/black&white/Apparel.jpg'
import IndustryBlack2 from '../../assets/images/industry/black&white/LaundryBlack.jpg'
import IndustryBlack3 from '../../assets/images/industry/black&white/textile.jpg'
import IndustryBlack4 from '../../assets/images/industry/black&white/AccessoriesBlack.jpg'
import IndustryBlack5 from '../../assets/images/industry/black&white/Embroidery.jpg'
import IndustryBlack6 from '../../assets/images/industry/black&white/PrintingBlack.jpg'
import IndustryBlack7 from '../../assets/images/industry/black&white/TransportationWhite.jpg'
import IndustryBlack8 from '../../assets/images/industry/black&white/C&F.jpg'
import IndustryBlack9 from '../../assets/images/industry/black&white/PrintMedia.jpg'
import IndustryBlack10 from '../../assets/images/industry/black&white/ElectronicsMediablack.jpg'
import IndustryBlack11 from '../../assets/images/industry/black&white/TeaState.jpg'


class AwardCertificationCsr extends React.Component{
    render(){
        return(
            <Fragment>
                <Row
                className='award-certification-csr-row'
                >
                    <Col 
                    className='award-certification-csr-col'
                    span={24}>
                        <Row>
                            <Col
                            className='award-certification-csr-child-col' 
                            span={8}>
                                <Card>
                                    <h2>Award</h2>
                                    <Row style={{
                                        // marginTop:"4vh"
                                    }}>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack}/>
                                        </Col>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack2}/>
                                        </Col>
                                        {/* <Col
                                        style={{
                                            padding:"5px"
                                        }} 
                                        span={8}>
                                            <img src={IndustryBlack3}/>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack}/>
                                        </Col>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack2}/>
                                        </Col>
                                        {/* <Col
                                        style={{
                                            padding:"5px"
                                        }} 
                                        span={8}>
                                            <img src={IndustryBlack3}/>
                                        </Col> */}
                                    </Row>
                                    <Row
                                    className='explore-sustainability'
                                    >
                                        <div className="box-1">
                                            <div className="btn btn-one">
                                                <span>Show More</span>
                                            </div>
                                        </div>
                                    </Row>
                                </Card>
                            </Col>
                            <Col
                            className='award-certification-csr-child-col'
                            span={8}>
                                <Card>
                                    <h2>Certification</h2>
                                    <Row style={{
                                        // marginTop:"4vh"
                                    }}>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack}/>
                                        </Col>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack2}/>
                                        </Col>
                                        {/* <Col
                                        style={{
                                            padding:"5px"
                                        }} 
                                        span={8}>
                                            <img src={IndustryBlack3}/>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack}/>
                                        </Col>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack2}/>
                                        </Col>
                                        {/* <Col
                                        style={{
                                            padding:"5px"
                                        }} 
                                        span={8}>
                                            <img src={IndustryBlack3}/>
                                        </Col> */}
                                    </Row>
                                    <Row
                                    className='explore-sustainability'
                                    >
                                        <div className="box-1">
                                            <div className="btn btn-one">
                                                <span>Show More</span>
                                            </div>
                                        </div>
                                    </Row>
                                </Card>
                            </Col>
                            <Col 
                            className='award-certification-csr-child-col'
                            span={8}>
                                <Card>
                                    <h2>CSR</h2>
                                    <Row style={{
                                        // marginTop:"4vh"
                                    }}>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack}/>
                                        </Col>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack2}/>
                                        </Col>
                                        {/* <Col
                                        style={{
                                            padding:"5px"
                                        }} 
                                        span={8}>
                                            <img src={IndustryBlack3}/>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack}/>
                                        </Col>
                                        <Col
                                        style={{
                                            padding:"6px"
                                        }} 
                                        span={12}>
                                            <img src={IndustryBlack2}/>
                                        </Col>
                                        {/* <Col
                                        style={{
                                            padding:"5px"
                                        }} 
                                        span={8}>
                                            <img src={IndustryBlack3}/>
                                        </Col> */}
                                    </Row>
                                    <Row
                                    className='explore-sustainability'
                                    >
                                        <div className="box-1">
                                            <div className="btn btn-one">
                                                <span>Show More</span>
                                            </div>
                                        </div>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
export default AwardCertificationCsr;