import React, { Fragment } from 'react';
import {Row,Col} from 'antd';
import newBrand from '../../../assets/images/newBrand/VF.png';
import newBrand2 from '../../../assets/images/newBrand/GAP.png'
import newBrand3 from '../../../assets/images/newBrand/KOHLS.png'
import newBrand4 from '../../../assets/images/newBrand/AEO.png'
import newBrand5 from '../../../assets/images/newBrand/VF.png'
import newBrand6 from '../../../assets/images/newBrand/JCPENNEY.png'
import newBrand7 from '../../../assets/images/newBrand/CALVINKLEIN.png'
import newBrand8 from '../../../assets/images/newBrand/ZARA.png'
import newBrand9 from '../../../assets/images/newBrand/PVH.png'
import newBrand10 from '../../../assets/images/newBrand/NEXT.png'
import newBrand11 from '../../../assets/images/newBrand/aba.png'
import newBrand12 from '../../../assets/images/newBrand/chart.png'
import newBrand13 from '../../../assets/images/newBrand/ESPRIT.png'
import newBrand14 from '../../../assets/images/newBrand/GARAGE.png'
import newBrand15 from '../../../assets/images/newBrand/H&M.png'
import newBrand16 from '../../../assets/images/newBrand/HOLLISTER.png'
import newBrand17 from '../../../assets/images/newBrand/JUSTICE.png'
import newBrand18 from '../../../assets/images/newBrand/NAPAPIJRI.png'
import newBrand19 from '../../../assets/images/newBrand/NAUTICA.png'
import newBrand20 from '../../../assets/images/newBrand/NorthFace.png'
import newBrand21 from '../../../assets/images/newBrand/SOLIVER.png'
import newBrand22 from '../../../assets/images/newBrand/TCHIBO.png'
import './index.css'

class AllBrand extends React.Component{
    render(){
        return(
            <Fragment>
                <Row className="all-brand-row">
                    <Col 
                    className="all-brand-col"
                    span={24}>
                        <Row
                        style={{
                            display:"flex"
                        }}
                        >
                            <div className="brand-images">
                                <img src={newBrand}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand2}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand3}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand4}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand5}/>
                            </div>
                        </Row>
                        <Row
                        style={{
                            display:"flex"
                        }}
                        >
                            <div className="brand-images">
                                <img src={newBrand6}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand7}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand8}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand9}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand10}/>
                            </div>
                        </Row>
                        <Row
                        style={{
                            display:"flex"
                        }}
                        >
                            <div className="brand-images">
                                <img src={newBrand11}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand12}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand13}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand14}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand15}/>
                            </div>
                        </Row>
                        <Row
                        style={{
                            display:"flex"
                        }}
                        >
                            <div className="brand-images">
                                <img src={newBrand16}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand17}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand18}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand19}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand20}/>
                            </div>
                        </Row>
                        <Row
                        style={{
                            display:"flex"
                        }}
                        >
                            <div className="brand-images">
                                <img src={newBrand21}/>
                            </div>
                            <div className="brand-images">
                                <img src={newBrand22}/>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
export default AllBrand;