import React, { Fragment } from 'react'
import { Breadcrumb, Layout, Menu,FloatButton} from 'antd';
import TopNavBar from '../../component/TopNavBar';
import HeroBackground from '../../component/VideoBg';
import GetKnowAbout from '../../component/GetKnowAbout';
import OurBrand from '../../component/OurBrand';
import OurOverview from '../../component/OurOverview';
import OverviewCountup from '../../component/OverviewCountup';
import KnowAboutUs from '../../component/KnowAboutUs';
import OurServices from '../../component/OurServices';
import SustainablityQuote from '../../component/SustainablityQuote';
import AwardCertificationCsr from '../../component/AwardCertificationCsr';
import TheLatest from '../../component/TheLatest';
import FooterComponent from '../../component/Footer';
import ContentSlideShow from '../../component/ContentSlideShow';
import Certification from '../../component/Certification';
import CSR from '../../component/CSR';
const { Header, Content, Footer } = Layout;

class HomePage extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <Fragment>
                <Layout>
                    <Header
                    style={{
                        // position: 'sticky',
                        position:"fixed",
                        top: 0,
                        // marginBottom:"60px",
                        overflow:"auto",
                        zIndex:1500,
                        width: '100%',
                        borderBottom:"1px solid grey"
                      }}
                    >
                        <TopNavBar title="Home"/>
                    </Header>
                    <Content>
                        <HeroBackground/>
                        <GetKnowAbout/>
                        {/* <OurBrand/> */}
                        {/* <OurOverview/> */}
                        <ContentSlideShow/>
                        <OverviewCountup/>
                        <KnowAboutUs/>
                        <OurServices/>
                        <SustainablityQuote isShow={true}/>
                        {/* <AwardCertificationCsr/> */}
                        {/* <TheLatest/> */}
                        <Certification/>
                        <CSR/>
                        {/* <TestSlide/> */}
                    </Content>
                    <Footer
                    style={{
                        textAlign:"center"
                    }}
                    >
                        <FloatButton.BackTop
                        duration="1000"
                        />
                        <FooterComponent/>
                        <span>
                            Copyright © Ha-Meem Group All Right Reserved.
                        </span>
                    </Footer>
                </Layout>
            </Fragment>
        )
    }
}
export default HomePage;