import React, { Fragment } from 'react'
import { Breadcrumb, Layout, Menu,FloatButton} from 'antd';
import TopNavBar from '../../component/TopNavBar';
import PageHeroBg from '../../component/HeroBg';
import FooterComponent from '../../component/Footer';
import AllBrand from '../../component/OurBrand/BrandDetails';
import ContactBg from '../../assets/images/industry_image/ElectronicsMedia.jpg'
const { Header, Content, Footer } = Layout;

class AllBrandPage extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <Fragment>
                <Layout>
                    <Header
                    style={{
                        position:"fixed",
                        top: 0,
                        overflow:"auto",
                        zIndex:1500,
                        width: '100%',
                        borderBottom:"1px solid grey"
                      }}
                    >
                        <TopNavBar title="All Brand"/>
                    </Header>
                    <Content>
                        <PageHeroBg 
                        bgTitle="All Brand"
                        bgImage={ContactBg}
                        />
                    </Content>
                    <AllBrand/>
                    <Footer
                    style={{
                        textAlign:"center"
                    }}
                    >
                        <FloatButton.BackTop
                        duration="1000"
                        />
                        <FooterComponent/>
                        <span>
                            Copyright © Ha-Meem Group All Right Reserved.
                        </span>
                    </Footer>
                </Layout>
            </Fragment>
        )
    }
}
export default AllBrandPage;