import React,{Fragment} from 'react'
import {Row,Col,Progress} from 'antd';
// import Video from '../../assets/video/video.mp4'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css'





import IndustryWhite from '../../assets/images/industry/black&white/ApparelWhite.jpg'
import IndustryWhite2 from '../../assets/images/industry/black&white/Laundry.jpg'
import IndustryWhite3 from '../../assets/images/industry/black&white/textileBLACK.jpg'
import IndustryWhite4 from '../../assets/images/industry/black&white/Accessories.jpg'
import IndustryWhite5 from '../../assets/images/industry/black&white/EmbroideryWhite.jpg'
import IndustryWhite6 from '../../assets/images/industry/black&white/Printing.jpg'
import IndustryWhite7 from '../../assets/images/industry/black&white/Transportation.jpg'
import IndustryWhite8 from '../../assets/images/industry/black&white/C&Fwhite.jpg'
import IndustryWhite9 from '../../assets/images/industry/black&white/PrintMediaBlack.jpg'
import IndustryWhite10 from '../../assets/images/industry/black&white/ElectronicsMedia.jpg'
import IndustryWhite11 from '../../assets/images/industry/black&white/TeaStateWhite.jpg'





import InsustryImage from '../../assets/images/industry_image/Apparel.jpg'
import InsustryImage2 from '../../assets/images/industry_image/Laundry.jpg'
import InsustryImage3 from '../../assets/images/industry_image/Textile.jpg'
import InsustryImage4 from '../../assets/images/industry_image/Accessories.jpg'
import InsustryImage5 from '../../assets/images/industry_image/Embroidery.jpg'
import InsustryImage6 from '../../assets/images/industry_image/PRINTING.jpg'
import InsustryImage7 from '../../assets/images/industry_image/Transportation.jpg'
import InsustryImage8 from '../../assets/images/industry_image/C&F.jpg'
import InsustryImage9 from '../../assets/images/industry_image/Newspaper.jpg'
import InsustryImage10 from '../../assets/images/industry_image/ElectronicsMedia.jpg'
import InsustryImage11 from '../../assets/images/industry_image/TeaState.jpg'


// import IndustryImage2560 from '../../assets/images/industry_image/High/Apparel.JPG'
// import IndustryImage2560 from '../../assets/images/industry_image/High/Apparel2.png'
import IndustryImage2560 from '../../assets/images/industry_image/High/Apparel3.png'
// import IndustryImage2560_2 from '../../assets/images/industry_image/High/laundryNew.JPG'
import IndustryImage2560_2 from '../../assets/images/industry_image/High/laundry2.png'
// import IndustryImage2560_3 from '../../assets/images/industry_image/High/textile.JPG'
import IndustryImage2560_3 from '../../assets/images/industry_image/High/textile2.png'
import IndustryImage2560_5 from '../../assets/images/industry_image/High/embroidery.png'
// import IndustryImage2560_10 from '../../assets/images/industry_image/High/Media.JPG'
import IndustryImage2560_10 from '../../assets/images/industry_image/High/Media2.png'

import IndustryImage1920 from '../../assets/images/industry_image/High/Apparel2.png'
// import IndustryImage1920 from '../../assets/images/industry_image/High/Apparel.JPG'
import IndustryImage1920_2 from '../../assets/images/industry_image/Four/laundryNew.JPG'
import IndustryImage1920_3 from '../../assets/images/industry_image/Four/textile.JPG'
import IndustryImage1920_5 from '../../assets/images/industry_image/Four/embroidery.png'
// import IndustryImage1920_10 from '../../assets/images/industry_image/Four/Media.JPG'
import IndustryImage1920_10 from '../../assets/images/industry_image/Four/test2.JPG'

import IndustryImage1366_2 from '../../assets/images/industry_image/Three/laundryNew.JPG'
import IndustryImage1366_3 from '../../assets/images/industry_image/Three/textile.JPG'
import IndustryImage1366_5 from '../../assets/images/industry_image/Three/embroidery.png'
import IndustryImage1366_10 from '../../assets/images/industry_image/Three/Media.JPG'

import IndustryImage1024_2 from '../../assets/images/industry_image/Two/laundryNew.JPG'
import IndustryImage1024_3 from '../../assets/images/industry_image/Two/textile.JPG'
import IndustryImage1024_5 from '../../assets/images/industry_image/Two/embroidery.png'
import IndustryImage1024_10 from '../../assets/images/industry_image/Two/Media.JPG'




class HeroBackground extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            fill:0,
            count:0,
            settings2:{},
            industryImages:{
                one:"",
                two:"",
                three:"",
                four:"",
                five:"",
                six:"",
                seven:"",
                eight:"",
                nine:"",
                ten:"",
                eleven:""
            }
        };
    }

    check=()=>{
        this.setState(prevstate => ({ fill: prevstate.fill + .5}));
        //console.log("F",this.state.fill)
    }
    componentDidMount() {
        this.setState({
        nav1: this.slider1,
        nav2: this.slider2
        });

        this.handleSizeSettings();
        // setInterval(this.check, 100);
    }
    handleSizeSettings=()=>{
        let width=window.innerWidth;
        let industryImages={...this.state.industryImages};


        industryImages.one=IndustryImage1920;
        industryImages.two=IndustryImage1920_2;
        industryImages.three=IndustryImage1920_3;
        industryImages.four=InsustryImage4;
        industryImages.five=IndustryImage1920_5;
        industryImages.six=InsustryImage6;
        industryImages.seven=InsustryImage7;
        industryImages.eight=InsustryImage8;
        industryImages.nine=InsustryImage9;
        industryImages.ten=IndustryImage1920_10;
        industryImages.eleven=InsustryImage11;

        if(width>=2550){
            industryImages.one=IndustryImage2560;
            industryImages.two=IndustryImage2560_2;
            industryImages.three=IndustryImage2560_3;
            industryImages.five=IndustryImage2560_5;
            industryImages.ten=IndustryImage2560_10;
        }else if(width>=1915){
            industryImages.one=IndustryImage1920;
            industryImages.two=IndustryImage1920_2;
            industryImages.three=IndustryImage1920_3;
            industryImages.five=IndustryImage1920_5;
            industryImages.ten=IndustryImage1920_10;
        }else if(width>=1360){
            industryImages.two=IndustryImage1366_2;
            industryImages.three=IndustryImage1366_3;
            industryImages.five=IndustryImage1366_5;
            industryImages.ten=IndustryImage1366_10;
        }else if(width>=1020){
            industryImages.two=IndustryImage1024_2;
            industryImages.three=IndustryImage1024_3;
            industryImages.five=IndustryImage1024_5;
            industryImages.ten=IndustryImage1024_10;
        }
        else{
            industryImages.one=InsustryImage;
            industryImages.two=InsustryImage2;
            industryImages.three=InsustryImage3;
            industryImages.four=InsustryImage4;
            industryImages.five=InsustryImage5;
            industryImages.six=InsustryImage6;
            industryImages.seven=InsustryImage7;
            industryImages.eight=InsustryImage8;
            industryImages.nine=InsustryImage9;
            industryImages.ten=InsustryImage10;
            industryImages.eleven=InsustryImage11;
        }

        this.setState({
            industryImages
        })
    }
    handleProgress=()=>{
        // let fill=this.state.fill;
        // while(fill<100){
        //     setTimeout(()=>{
        //         // this.setState(state=>{
        //         //     fill:state.fill+20
        //         // })
               
        //     },1000)
        //     console.log("Hello")
        //     fill+=10;
        // }
        
    }
    // handleLoad=()=>{
    //     setTimeout(()=>{
    //         console.log("Called")
    //     },1000)
    // }
    resizeHandle=()=>{
        this.handleSizeSettings();
    }
    render(){
        let {industryImages}=this.state;
        let settings2 = {
            dots: false,
            // infinite: true,
            slidesToShow: 11,
            slidesToScroll: 1,
            autoplay: false,
            responsive: [
                // {
                //     breakpoint: 1920,
                //     settings: {
                //       slidesToShow: 11,
                //       slidesToScroll: 1,
                //     }
                // },
                // {
                //     breakpoint: 1400,
                //     settings: {
                //       slidesToShow: 11,
                //       slidesToScroll: 1,
                //     }
                // },
                // {
                //     breakpoint: 1390,
                //     settings: {
                //       slidesToShow: 11,
                //       slidesToScroll: 1,
                //     }
                // },
                // {
                //     breakpoint: 1380,
                //     settings: {
                //       slidesToShow: 11,
                //       slidesToScroll: 1,
                //     }
                // },
                // {
                //     breakpoint: 1370,
                //     settings: {
                //       slidesToShow: 11,
                //       slidesToScroll: 1,
                //     }
                // },
                // {
                //     breakpoint: 1366,
                //     settings: {
                //       slidesToShow: 11,
                //       slidesToScroll: 1,
                //     }
                // },
                // {
                //     breakpoint: 1360,
                //     settings: {
                //       slidesToShow: 8,
                //       slidesToScroll: 1,
                //     }
                // },
                // {
                //   breakpoint: 1024,
                //   settings: {
                //     slidesToShow: 8,
                //     slidesToScroll: 1,
                //   }
                // },
                // {
                //   breakpoint: 600,
                //   settings: {
                //     slidesToShow: 8,
                //     slidesToScroll: 1,
                //     // initialSlide: 2
                //   }
                // },
                // {
                //   breakpoint: 480,
                //   settings: {
                //     slidesToShow: 8,
                //     slidesToScroll: 1
                //   }
                // }
              ]
            // speed: 2000,
            // autoplaySpeed: 5000,
            // cssEase: "linear"
        };
        const settings = {
            arrows:true,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 300,
            autoplaySpeed: 6000,
            cssEase: "linear",
            pauseOnHover: false,
            dotsClass: "button__bar",
            appendDots: dots => (
                <div
                  style={{
                    padding:"0px",
                    margin:"0px"
                    // padding: "10px",
                    // width:"100vw"
                  }}
                >
                    <ul 
                    style={{ 
                        margin: "0px",
                        width:"100vw",
                        padding:"0",
                        overflow:"hidden"
                    }}>
                     {dots} 
                    </ul>
                </div>
              ),
            customPaging: i => (
                <div
                  style={{
                    width: "9.09vw !important",
                    color: "blue",
                    overflow:"hidden"
                  }}
                >
                    <div
                        style={{
                            width:"9.09vw",
                            padding:"5px"
                        }}
                        >
                            {i==0?<img src={IndustryWhite}/>:""}
                            {i==1?<img src={IndustryWhite2}/>:""}
                            {i==2?<img src={IndustryWhite3}/>:""}
                            {i==3?<img src={IndustryWhite4}/>:""}
                            {i==4?<img src={IndustryWhite5}/>:""}
                            {i==5?<img src={IndustryWhite6}/>:""}
                            {i==6?<img src={IndustryWhite7}/>:""}
                            {i==7?<img src={IndustryWhite8}/>:""}
                            {i==8?<img src={IndustryWhite9}/>:""}
                            {i==9?<img src={IndustryWhite10}/>:""}
                            {i==10?<img src={IndustryWhite11}/>:""}
                        </div>
                </div>
              )
            // nextArrow: ">>",
            // prevArrow: "<<>>",
            // afterChange: current => this.setState({ activeSlide2: current })
            // afterChange: current => this.setState({ fill:7.5 }),
            // beforeChange: current => this.setState({ fill:-5 })
            // afterChange: current => this.setState({ fill:1 }),
            // beforeChange: current => this.setState({ fill:0 })
        };
        window.addEventListener('resize', this.resizeHandle)
        return(
            <Fragment>
                <Row>
                    <Col
                    span={24}
                    >
                        <Row
                        className='slide-image-row'
                        >
                            <Col
                            span={24}
                            className='slide-image-col'
                            >
                                <Slider
                                {...settings}
                                className="main-slide"
                                // asNavFor={this.state.nav2}
                                // ref={slider => (this.slider1 = slider)}
                                >
                                    <div
                                    className='slide-image'
                                    
                                    >
                                        <img src={industryImages.one}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.two}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.three}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.four}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.five}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.six}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img 
                                        src={industryImages.seven}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img 
                                        src={industryImages.eight}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.nine}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.ten}/>
                                    </div>
                                    <div
                                    className='slide-image'
                                    >
                                        <img src={industryImages.eleven}/>
                                    </div>
                                </Slider>
                            </Col>
                            {/* <Progress
                                onLoad={this.handleLoad} 
                                percent={this.state.fill}
                                showInfo={false}
                            /> */}
                            {/* <Col span={24}>
                            <div style={{
                                width:`${this.state.fill}%`,
                                height:".5vh",
                                // zIndex:"5",
                                // marginBottom:"2vh",
                                backgroundColor:"rgb(226 182 123) "
                            }}>

                            </div>
                            </Col> */}
                        </Row>
                        {/* <Row
                        style={{
                            padding:"0",
                            margin:"0"
                        }}
                        >
                            <Col
                            span={24}
                            style={{
                                padding:"0",
                                margin:"0",
                                height:"1vh !important"
                            }}
                            >
                                <Progress 
                                percent={84}
                                showInfo={false}
                                 />
                            </Col>
                        </Row> */}
                        {/* <Row
                        className='slider-controll-row'
                        style={{
                            overflow:"hidden !important",
                            // marginTop:"-2vh"
                        }}
                        >
                            <Col
                            className='slider-controll-col'
                            >
                                <Slider
                                className="control-slide"
                                // {...this.state.settings2}
                                {...settings2}
                                asNavFor={this.state.nav1}
                                ref={slider => (this.slider2 = slider)}
                                // slidesToShow={3}
                                swipeToSlide={false}
                                focusOnSelect={true}
                                >
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite2}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite3}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite4}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite5}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite6}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite7}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite8}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite9}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite10}/>
                                    </div>
                                    <div
                                    className='slider-control'
                                    >
                                       <img src={IndustryWhite11}/>
                                    </div>
                                </Slider>
                            </Col>
                        </Row> */}
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
export default HeroBackground