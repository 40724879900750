import React,{Fragment} from 'react'
import {Row,Col,Button} from 'antd'
import {Link} from 'react-router-dom';
import Carousel from "react-multi-carousel";
import Slider from "react-slick";


import newBrand from '../../assets/images/newBrand/VF.png';
import newBrand2 from '../../assets/images/newBrand/GAP.png'
import newBrand3 from '../../assets/images/newBrand/KOHLS.png'
import newBrand4 from '../../assets/images/newBrand/AEO.png'
import newBrand5 from '../../assets/images/newBrand/aba.png'
import newBrand6 from '../../assets/images/newBrand/JCPENNEY.png'
import newBrand7 from '../../assets/images/newBrand/CALVINKLEIN.png'
import newBrand8 from '../../assets/images/newBrand/ZARA.png'
import newBrand9 from '../../assets/images/newBrand/PVH.png'
import newBrand10 from '../../assets/images/newBrand/NEXT.png'
import newBrand11 from '../../assets/images/newBrand/aba.png'
import newBrand12 from '../../assets/images/newBrand/chart.png'
import newBrand13 from '../../assets/images/newBrand/ESPRIT.png'
import newBrand14 from '../../assets/images/newBrand/GARAGE.png'
import newBrand15 from '../../assets/images/newBrand/H&M.png'
import newBrand16 from '../../assets/images/newBrand/HOLLISTER.png'
import newBrand17 from '../../assets/images/newBrand/JUSTICE.png'
import newBrand18 from '../../assets/images/newBrand/NAPAPIJRI.png'
import newBrand19 from '../../assets/images/newBrand/NAUTICA.png'
import newBrand20 from '../../assets/images/newBrand/NorthFace.png'
import newBrand21 from '../../assets/images/newBrand/SOLIVER.png'
import newBrand22 from '../../assets/images/newBrand/TCHIBO.png'


import './index.css'


// class OurBrand extends React.Component{
//     render(){
//         const responsive = {
//             superLargeDesktop: {
//               // the naming can be any, depends on you.
//               breakpoint: { max: 4000, min: 3000 },
//               items: 5
//             },
//             desktop: {
//               breakpoint: { max: 3000, min: 1024 },
//               items: 8
//             },
//             tablet: {
//               breakpoint: { max: 1024, min: 464 },
//               items: 2
//             },
//             mobile: {
//               breakpoint: { max: 464, min: 0 },
//               items: 1
//             }
//         };
//         return(
//             <Fragment>
//                 <Row
//                 className="our-brand-row"
//                 >
//                     <Col
//                     span={24}
//                     className="our-brand-col"
//                     >
//                         <Carousel
//                         arrows={false}
//                         autoPlay
//                         autoPlaySpeed={3000} 
//                         additionalTransfrom={0}
//                         //arrows
//                         //autoPlaySpeed={3000}
//                         centerMode={false}
//                         className=""
//                         containerClass="container-with-dots"
//                         dotListClass=""
//                         draggable
//                         focusOnSelect={false}
//                         infinite
//                         itemClass=""
//                         keyBoardControl
//                         minimumTouchDrag={80}
//                         pauseOnHover
//                         renderArrowsWhenDisabled={false}
//                         renderButtonGroupOutside={false}
//                         renderDotsOutside={false}

//                         rewind={false}
//                         rewindWithAnimation={false}
//                         rtl={false}
//                         //shouldResetAutoplay
//                         showDots={false}
//                         sliderClass=""
//                         slidesToSlide={1}
//                         swipeable

//                         responsive={responsive}
//                         //infinite
//                         // slidesToSlide={1}
//                         >
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={One}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Two}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Three}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Four}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>   
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Five}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Six}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Seven}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Eight}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
                                
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                     <img src={Nine}/>
//                                     <div class="middle">
//                                         <span className='text'>
//                                             Brand Name
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                             className='carousel-div'
//                             >
//                                 <div
//                                 className="carousel-div-inner"
//                                 >
//                                      <img src={Ten}/>
//                                     <div class="middle">
//                                       <span className='text'>
//                                         Brand Name
//                                       </span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Carousel>
//                     </Col>
//                 </Row>
//             </Fragment>
//         )
//     }
// }
// export default OurBrand;

export default class OurBrand extends React.Component {
    constructor(){
        super()
        this.state={
            collapsed:true
        }
    }
    handleExpandCollapsed=()=>{
        let height=document.getElementById("expand-id").clientHeight;
        //console.log("Height ::: ",height);
        if(height>0){
            height=height+(height/2)
            window.scrollTo(0, height);
        }
        
        this.setState({
            collapsed:!this.state.collapsed
        })
    }
    render() {
      const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 4,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
      };
      
      return (
        <Fragment>
            <Row
            className="our-brand-row"
            >
                <Col
                span={24}
                className="our-brand-col"
                >
                    <Row
                    style={{
                        display:"flex"
                    }}
                    >
                        <div className="brand-images">
                            <img src={newBrand}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand2}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand3}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand4}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand5}/>
                        </div>
                    </Row>
                    <Row
                    style={{
                        display:"flex"
                    }}
                    >
                        <div className="brand-images">
                            <img src={newBrand6}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand7}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand8}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand9}/>
                        </div>
                        <div className="brand-images">
                            <img src={newBrand10}/>
                        </div>
                    </Row>
                    <Row 
                    className={this.state.collapsed?"collapsed-div":"expand-div"}
                    id="expand-id"
                    >
                        <Col span={24}>
                            <Row
                            style={{
                                display:"flex"
                            }}
                            >
                                <div className="brand-images">
                                    <img src={newBrand11}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand12}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand13}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand14}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand15}/>
                                </div>
                            </Row>
                            <Row
                            style={{
                                display:"flex"
                            }}
                            >
                                <div className="brand-images">
                                    <img src={newBrand16}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand17}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand18}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand19}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand20}/>
                                </div>
                            </Row>
                            <Row
                            style={{
                                display:"flex"
                            }}
                            >
                                <div className="brand-images">
                                    <img src={newBrand21}/>
                                </div>
                                <div className="brand-images">
                                    <img src={newBrand22}/>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row 
                    style={{
                        marginTop:"8vh"
                    }}>
                        <Col 
                        span={24}
                        className="our-brand"
                        >
                      
                                <div
                                onClick={this.handleExpandCollapsed}
                                className="btn box-1">
                                    <div className="btn btn-one">
                                        <span>{this.state.collapsed?"See More Clients":"See Less Clients"}</span>
                                    </div>
                                </div>
                          
                        </Col>
                    </Row>
                    {/* <Slider {...settings}>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img
                                    className="brand-large-img"
                                    src={One}
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP1}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div
                                className="carousel-div"
                                >
                                    <div
                                    className='carousel-div-inner'
                                    >
                                    <img 
                                    src={Two}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP2}/>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Three}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                   
                                     <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP3}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Four}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                  
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP4}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Five}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                   
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP5}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Six}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Three}
                                    />
                                  
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP6}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Seven}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                  
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP8}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Three}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                   
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP3}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Four}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                  
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP4}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            className="carousel-div"
                            >
                                <div
                                className='carousel-div-inner'
                                >
                                    <img 
                                    src={Two}
                                    className="brand-large-img"
                                    />
                                    <img
                                    className="brand-large-img"
                                    src={Six}
                                    />
                                  
                                    <div className="overlay">
                                        <div className="text">
                                            <img 
                                            style={{
                                                height:"100%",
                                                width:"30%",
                                                marginLeft:"35%"
                                            }}
                                            src={BP2}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider> */}
                </Col>
            </Row>    
        </Fragment>
       
      );
    }
  }