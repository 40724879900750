import React, { Fragment } from 'react'
import {Row,Col} from 'antd'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import NoImage from '../../../assets/images/noimage.png'
import './index.css'

class Main extends React.Component{
    constructor(){
        super()
        this.state={
            stickyClass:"sticky"
        }
        this.refName = React.createRef();
    }
    onScroll=()=>{
        if(window.scrollY>100){
            this.setState({
                stickyClass:"sticky stick"
            })
        }else if(window.scrollY<100){
            this.setState({
                stickyClass:"sticky"
            })
        }

        // let top = document.getElementById( "mission-vission" ).Top;
        // console.log(top)
    }
    componentDidMount=()=>{
        window.addEventListener('scroll',this.onScroll)
        
    }
    // getHeight=()=>{
    //     console.log("JJJ : ")
    // }
    getoffSet = e => {
        console.log(e, e.natiiveEvent.target.childNodes[0].offsetTop)
    }
    onScrollT=()=>{
        // let offsetTop = this.refName.current.offsetTop;
        // console.log(offsetTop)
        console.log('FFF')
    }
    render(){
        return(
            <Fragment>
                <Row
                className="about-main-row"
                >
                    <Col
                    span={24}
                    className="about-main-col"
                    >
                        <Row
                        className="about-sticky-section-row"
                        >
                            <Col
                            span={24}
                            className={this.state.stickyClass}
                            >
                                <Row>
                                    <Col
                                    span={24}
                                    style={{
                                      
                                    }}
                                    className="about-us-haslink"
                                    >
                                        <ul
                                        >
                                            <li>
                                                <NavHashLink
                                                smooth
                                                activeClassName="active-hashlink"
                                                to={'/laundry#one'}
                                                >
                                                    Segment One
                                                </NavHashLink>
                                            </li>
                                            <li>
                                                <NavHashLink
                                                smooth
                                                activeClassName="active-hashlink"
                                                to={'/laundry#two'}
                                                >
                                                    Segment Two
                                                </NavHashLink>
                                            </li>
                                            <li>
                                                <NavHashLink
                                                smooth
                                                activeClassName="active-hashlink"
                                                to={'/laundry#three'}
                                                >
                                                    Segment three
                                                </NavHashLink>
                                            </li>
                                            <li>
                                                <NavHashLink
                                                smooth
                                                activeClassName="active-hashlink"
                                                to={'/laundry#four'}
                                                >
                                                    Segment four
                                                </NavHashLink>
                                            </li>
                                        </ul> 
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row
                        className="about-story-row"
                        >
                            <Col
                            span={24}
                            className="about-content about-story-col"
                            id="one"
                            >
                                <Row>
                                    <Col span={24} className="about-title">
                                        <h3>Segment One Heading...</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="about-description">
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <Row>
                                            <Col span={4}>
                                            </Col>
                                            <Col span={16} style={{textAlign:"center"}}>
                                                <img src={NoImage}/>
                                            </Col>
                                        </Row>
                                        <br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                            span={24}
                            className="about-content"
                            id="two"
                            // ref={this.refName}
                            onScroll={this.onScrollT}
                            // onScroll={(e) => this.getoffSet(e)}
                            >
                                <Row>
                                    <Col span={24} className="about-title">
                                        <h3>Segment Two Heading...</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="about-description">
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <Row>
                                            <Col span={4}>
                                            </Col>
                                            <Col span={16} style={{textAlign:"center"}}>
                                                <img src={NoImage}/>
                                            </Col>
                                        </Row>
                                        <br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                            span={24}
                            className="about-content"
                            id="three"
                            // ref={this.refName}
                            onScroll={this.onScrollT}
                            // onScroll={(e) => this.getoffSet(e)}
                            >
                                <Row>
                                    <Col span={24} className="about-title">
                                        <h3>Segment Three Heading...</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="about-description">
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <Row>
                                            <Col span={4}>
                                            </Col>
                                            <Col span={16} style={{textAlign:"center"}}>
                                                <img src={NoImage}/>
                                            </Col>
                                        </Row>
                                        <br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                            span={24}
                            className="about-content"
                            id="four"
                            // ref={this.refName}
                            onScroll={this.onScrollT}
                            // onScroll={(e) => this.getoffSet(e)}
                            >
                                <Row>
                                    <Col span={24} className="about-title">
                                        <h3>Segment Four Heading...</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="about-description">
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <Row>
                                            <Col span={4}>
                                            </Col>
                                            <Col span={16} style={{textAlign:"center"}}>
                                                <img src={NoImage}/>
                                            </Col>
                                        </Row>
                                        <br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                        <br/><br/><br/>
                                        <span>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
export default Main;