import React,{Fragment} from 'react'
import {Row,Col,Button,Badge} from 'antd'
import {
    PlusOutlined
} from '@ant-design/icons'
import Carousel from "react-multi-carousel";
// import One from '../../../assets/images/products/1.jpg'
// import Two from '../../../assets/images/products/2.jpg'
// import Three from '../../../assets/images/products/3.jpg'
// import Four from '../../../assets/images/products/4.jpg'
// import Five from '../../../assets/images/products/5.jpg'
// import Six from '../../../assets/images/products/6.jpg'
// import Seven from '../../../assets/images/products/7.jpg'
// import Eight from '../../../assets/images/products/8.jpg'
// import Nine from '../../../assets/images/products/jacket05.png'

import Spining from '../../../assets/images/TabData/Spining/Spining.JPG';
import Spining2 from '../../../assets/images/TabData/Spining/Spining_2.JPG';
import Spining3 from '../../../assets/images/TabData/Spining/Spining_3.JPG';
import Spining4 from '../../../assets/images/TabData/Spining/Spining_4.JPG';
import Spining5 from '../../../assets/images/TabData/Spining/Spining_5.JPG';
import Spining6 from '../../../assets/images/TabData/Spining/Spining_6.JPG';

import Slider from "react-slick";




export default class SpiningSlideShow extends React.Component {
    constructor(){
        super()
        this.state={
            enterKey:""
        }
    }
    handleMouseEnter=(i)=>{
        this.setState({
            enterKey:i
        })
    }
    hanleMouseLeave=(i)=>{
        this.setState({
            enterKey:''
        })
    }
    render() {
        let {enterKey}=this.state
        let settings = {
            dots: false,
            infinite: true,
            //speed: 500,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 4000,
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0,
            responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 5,
                slidesToScroll: 4
                }
            }
            ]
        };

        return (
            <Fragment>
                <Row
                className="our-slideshow-row"
                >
                    <Col
                    className="our-slideshow-col"
                    span={24}
                    >
                        <Slider {...settings}>
                            <div
                            className='carousel-main'
                            >
                                <div
                                className="carousel-div"
                                onMouseEnter={this.handleMouseEnter.bind(this,0)}
                                onMouseLeave={this.hanleMouseLeave.bind(this,0)}
                                >
                                    <div
                                    style={{
                                        height:"100%",
                                        overflow:"hidden"
                                    }}
                                    className="product-image"
                                    >
                                        <img 
                                        src={Spining}
                                        className={enterKey===0?"overly-image":""}
                                        />
                                        <div className="middle">
                                            {/* <span className='text'>
                                                <Button
                                                size='small'
                                                className="product-btn"
                                                >
                                                    <PlusOutlined />
                                                </Button>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            className='carousel-main'
                            >
                                <div
                                className="carousel-div"
                                onMouseEnter={this.handleMouseEnter.bind(this,1)}
                                onMouseLeave={this.hanleMouseLeave.bind(this,1)}
                                >
                                    <div
                                    style={{
                                        height:"100%",
                                        overflow:"hidden"
                                    }}
                                    className="product-image"
                                    >
                                        <img 
                                        src={Spining2}
                                        className={enterKey===1?"overly-image":""}
                                        />
                                        <div className="middle">
                                            {/* <span className='text'>
                                                <Button
                                                size='small'
                                                className="product-btn"
                                                >
                                                    <PlusOutlined />
                                                </Button>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            className='carousel-main'
                            >
                                <div
                                className="carousel-div"
                                onMouseEnter={this.handleMouseEnter.bind(this,2)}
                                onMouseLeave={this.hanleMouseLeave.bind(this,2)}
                                >
                                    <div
                                    style={{
                                        height:"100%",
                                        overflow:"hidden"
                                    }}
                                    className="product-image"
                                    >
                                        <img 
                                        src={Spining3}
                                        className={enterKey===2?"overly-image":""}
                                        />
                                        <div className="middle">
                                            {/* <span className='text'>
                                                <Button
                                                size='small'
                                                className="product-btn"
                                                >
                                                    <PlusOutlined />
                                                </Button>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            className='carousel-main'
                            >
                                <div
                                className="carousel-div"
                                onMouseEnter={this.handleMouseEnter.bind(this,3)}
                                onMouseLeave={this.hanleMouseLeave.bind(this,3)}
                                >
                                    <div
                                    style={{
                                        height:"100%",
                                        overflow:"hidden"
                                    }}
                                    className="product-image"
                                    >
                                        <img 
                                        src={Spining4}
                                        className={enterKey===3?"overly-image":""}
                                        />
                                        <div className="middle">
                                            {/* <span className='text'>
                                                <Button
                                                size='small'
                                                className="product-btn"
                                                >
                                                    <PlusOutlined />
                                                </Button>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            className='carousel-main'
                            >
                                <div
                                className="carousel-div"
                                onMouseEnter={this.handleMouseEnter.bind(this,4)}
                                onMouseLeave={this.hanleMouseLeave.bind(this,4)}
                                >
                                    <div
                                    style={{
                                        height:"100%",
                                        overflow:"hidden"
                                    }}
                                    className="product-image"
                                    >
                                        <img 
                                        src={Spining5}
                                        className={enterKey===4?"overly-image":""}
                                        />
                                        <div className="middle">
                                            {/* <span className='text'>
                                                <Button
                                                size='small'
                                                className="product-btn"
                                                >
                                                    <PlusOutlined />
                                                </Button>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            className='carousel-main'
                            >
                                <div
                                className="carousel-div"
                                onMouseEnter={this.handleMouseEnter.bind(this,5)}
                                onMouseLeave={this.hanleMouseLeave.bind(this,5)}
                                >
                                    <div
                                    style={{
                                        height:"100%",
                                        overflow:"hidden"
                                    }}
                                    className="product-image"
                                    >
                                        <img 
                                        src={Spining6}
                                        className={enterKey===5?"overly-image":""}
                                        />
                                        <div className="middle">
                                            {/* <span className='text'>
                                                <Button
                                                size='small'
                                                className="product-btn"
                                                >
                                                    <PlusOutlined />
                                                </Button>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}