import React from 'react'
// import 'antd/dist/antd.min.css';
import {BrowserRouter,HashRouter} from 'react-router-dom'
import MyRoute from './MyRoute';
import './assets/css/index.css'
import "react-multi-carousel/lib/styles.css";

function App() {
  return (
    // <div >
    //   <TestSlide/>
    // </div>
    <HashRouter>
      <MyRoute/>
    </HashRouter>
  );
}

export default App;
